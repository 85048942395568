"use client";
import * as Sentry from "@sentry/nextjs";

export function reportError(error, errorInfo) {
  Sentry.withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key]);
    });
    Sentry.captureException(error);
  });
}
