import React, { useState, ReactNode, useContext } from 'react';
import { NavBar } from '@/components/layouts/NavBar';
import SideBar from '@/components/layouts/SideBar';
import ErrorProvider from '@/providers/ErrorProvider';
import { cn } from '@/helpers/utils';
import Grid from '@mui/material/Grid';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import InboxIcon from '@mui/icons-material/Inbox';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import { HeadSetIcon } from "@/components/base/Icons/HeadSet";
import { TicketIcon } from '@/components/base/Icons/Ticket';
import { useLanguage } from '@/hooks/useLanguage';
import { useLogout } from '@/hooks/useLogout';
import { useRouter }  from 'next/navigation';
import { AppContext } from '@/contexts/AppContext'
import { useAppRoutes } from '@/hooks/useAppRoutes';

type AppWrapperProps = {
  login?: boolean;
  className?: string;
  mainClass?: string;
  noFooter?: boolean;
  children: ReactNode;
}

export const AppWrapper = ({ login = false, className, mainClass = '', noFooter = false, children }: AppWrapperProps) => {
  const [value, setValue] = useState<number>(0);
  const { lang } = useLanguage()
  const { handleLogout } = useLogout()
  const router = useRouter()
  const { setNavLoading } = useContext(AppContext)
  
  const { getRoleBasedRoutes } = useAppRoutes();
  const roleBasedRoutes = getRoleBasedRoutes();

  const ButtonMenu = ({url, icon, label}) => {
    const handleNavigate = () => {
      setNavLoading(true)
      router.push(url)
    }

    if (!roleBasedRoutes?.map(role => role.url)?.includes(url)) {
      return null;
    }

    return (
      <BottomNavigationAction 
        label={label} 
        icon={icon} 
        className="border-0 border-r border-solid border-white/30" 
        onClick={handleNavigate} 
        sx={{
          color: 'white',
          '&.Mui-selected': {
            color: '#FFFFFF',
          },
          '.MuiBottomNavigationAction-label': {
            opacity: 1,
          }
        }}      
      />
    )
  }

  return (
    <main className={cn('appWrapper font-main overflow-x-hidden', mainClass)}>
      {!login && <NavBar />}
      <div className={cn(
        !login ? 'mainWrapper w-full md:w-[calc(100vw-57px)] md:ml-14 pl-4 pb-0 overflow-auto' : '',
        className || 'pt-4 pr-4'
      )}>
        <Grid container>
          <Grid item xs={12} className="grow">
            {!login && <SideBar />}
            <ErrorProvider>
              {children}
            </ErrorProvider>
            {(!login && !noFooter) && (
              <footer>
                <p className="text-slate-600 dark:text-slate-200 text-right text-small font-light py-1 mr-2">© Powered by <strong>Cogni2 AI</strong></p>
              </footer>
            )}
          </Grid>
        </Grid>
      </div>
      {!login && 
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          className="flex lg:hidden fixed z-50 bottom-0 w-full shadow-lg bg-secondary py-1"
        >
          <ButtonMenu 
            label={lang('menu.home')} 
            icon={<HomeIcon className="text-white -mt-2" />} 
            url="/home"
          />
           <ButtonMenu 
            label={lang('menu.inbox')} 
            icon={<InboxIcon className="text-white -mt-2" />} 
            url="/inbox"
          />
           <ButtonMenu 
            label={lang('menu.copilot')} 
            icon={<HeadSetIcon className="text-white -mt-2" />} 
            url="/copilot"
          />
          <ButtonMenu 
            label="Tickets"
            icon={<TicketIcon className="text-white -mt-2" />} 
            url="/tickets/1"
          />
          <BottomNavigationAction 
            label={lang('logout')} 
            icon={<LogoutIcon className="text-white mt-2" />} 
            onClick={handleLogout} 
            sx={{ color: 'white' }}
          />
        </BottomNavigation>
      }
    </main>
  )
}