import React, { memo, useContext, MouseEvent, useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'
import useDeviceDetection from '@/hooks/useDeviceDetection'
import { Badge, ListItem, ListItemButton, ListItemIcon, Popper, Tooltip } from '@mui/material'
import { AppContext } from '@/contexts/AppContext'
import { DevicesEnum } from '@/enum/Devices'
import { Route } from '@/interfaces/IRoute'
import { getMenuIcon } from '@/helpers/components'

interface SidebarItemProps {
  route?: Route
  active: boolean
  text?: string
  onClick?: () => void
  icon?: JSX.Element
}

export const SidebarItem: React.FC<SidebarItemProps> = memo(({ route, active, text, onClick, icon }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openSubMenu, setOpenSubMenu] = useState<boolean>(false);
  const { navOpen, setNavOpen, setNavLoading } = useContext(AppContext)
  const device = useDeviceDetection()
  const router = useRouter()
  const { text: RouteText, url, icon: RouteIcon, notification } = route || {}

  const hasSubmenu: boolean = route && route?.children && route?.children?.length > 0 || false

  const handleClick = async (e: MouseEvent<HTMLDivElement>, url?: string) => {
    e.preventDefault()
    e.stopPropagation()

    if(url){
      setNavLoading?.(true)
      if (e.shiftKey || e.ctrlKey || e.metaKey) {
        window.open(url, '_blank');
        setNavLoading?.(false)
      } else {
        router.push(url);
      }
      if (device !== DevicesEnum.desktop) {
        setNavOpen(false)
      }
    } else {
      onClick?.()
    }
  }

  useEffect(() => {
    return () => setNavLoading?.(false);
  }, [setNavLoading])

  const handleSubmenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSubMenu(true);
  };

  const handleSubmenuClose = () => {
    setAnchorEl(null);
    setOpenSubMenu(false);
  };

  return (
    <ListItem 
      disablePadding 
      className="block relative"
      onMouseEnter={handleSubmenuOpen}
      onMouseLeave={handleSubmenuClose}
    >
      <ListItemButton
        onClick={(e) => handleClick(e, url)}
        className={`${navOpen ? 'px-3' : 'px-0' } normal-case relative font-normal text-center min-w-auto py-3.5 hover:bg-tertiary ${active || openSubMenu ? 'bg-tertiary dark:bg-slate-900' : 'bg-transparent'}`}
      >
        <Tooltip title={RouteText || text} placement="top" arrow>
          <ListItemIcon className="text-white flex gap-2 items-center justify-center relative h-full">
            {RouteIcon ? getMenuIcon(RouteIcon) : icon}
            {navOpen ? <span className="text-xs">{RouteText || text}</span> : '' }
            {notification ? 
              <Badge badgeContent={notification} color="error" showZero max={99} className="-mt-5 -mr-1 scale-90" /> : ''
            }
          </ListItemIcon>
        </Tooltip>
      </ListItemButton>
      {hasSubmenu && (
        <Popper
          id={`route-${route?.icon}`}
          anchorEl={anchorEl}
          open={openSubMenu}
          placement="right-start"
          className="z-50 shadow-2xl"
        >
          {route?.children?.map((submenuItem, subIndex) => (
            <ListItemButton 
              key={subIndex} 
              onClick={(e) => {
                handleClick(e, submenuItem.url)
                handleSubmenuClose()
              }} 
              className="flex gap-1 items-center justify-start bg-tertiary hover:bg-blue-900 dark:bg-slate-900 text-white border-0 border-solid border-b border-blue-900 last:border-b-0"
            >
              <span className="mt-1 align-middle">{submenuItem.icon ? getMenuIcon(submenuItem.icon) : ''}</span> 
              <span className="text-xs font-light">{submenuItem.text}</span>
            </ListItemButton>
          ))}
        </Popper>
      )}
    </ListItem>
  )
});
